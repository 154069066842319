.tickie {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%,-40%,0);
    width: 50%;
    height: auto;
    cursor: pointer;
}

.title {
    width: 100%;
    height: auto;
}