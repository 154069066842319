.dual-ring-spinner {
display: inline-block;
width: 40px;
height: 40px;
padding: 20px 20px 30px 20px;
&:after {
    content: " ";
    display: block;
    width: 28px;
    height: 28px;
    margin: 4px 4px 4px -4px;
    border-radius: 50%;
    border: 6px solid #000;
    border-color: #000 transparent #000 transparent;
    animation: dual-ring-spinner 1.2s linear infinite;
  }
}
  
@keyframes dual-ring-spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
}
}
  